export type fileNameMapType = {
    "dbn_accrual_discrepancy_": string
    "dbn_discrepancy_": string
    "dbn_pf_": string;
    "dlw_discrepancy_": string;
    "dlw_pf_": string
    "dlw_accrual_discrepancy_": string;
    "sac_pf_": string;
    "dbn_nav_": string;
}

export const fileNameMap: fileNameMapType = {
    "dbn_accrual_discrepancy_": "Accrual Discrepancy",
    "dbn_discrepancy_": "Discrepancy",
    "dbn_pf_": "Performance Fee",
    "dlw_discrepancy_": "Discrepancy",
    "dlw_pf_": "Performance Fee",
    "dlw_accrual_discrepancy_": "Accrual Discrepancy",
    "sac_pf_": "Performance Fee",
    "dbn_nav_": "Monthly NAV Report"
}
